<template>
  <div class="player-vote-counter">
    <div class="minus">
      <template v-if="!readonly">
        <button @click="update(-5)" :disabled="value === 0">-5</button>
        <button @click="update(-1)" :disabled="value === 0">-1</button>
      </template>
    </div>
    <div class="rest">
      <span>{{ playerName }}:</span>
      <span class="count">{{ value }}</span>
      <template v-if="!readonly">
        <button @click="update(1)">+1</button>
        <button @click="update(5)">+5</button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayerVoteCounter",
  props: ["playerName", "value", "readonly"],
  methods: {
    update(amount) {
      this.$emit("input", Math.max(this.value + amount, 0));
    },
  },
};
</script>

<style scoped lang="scss">
.player-vote-counter {
  font-size: 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-right: 0.25rem;
  }

  .count {
    margin: 0 0.25rem 0 0.25rem;
  }
}

button {
  font-size: 1rem;
  min-width: 1.5rem;
}
</style>
