<template>
  <div class="container">
    <div class="col">
      <div class="controls">
        <button v-if="shared.selectedCard" @click="set('selectedCard', null)">
          К списку
        </button>
        <div>
          <input v-model="search" type="text" placeholder="Поиск" />
        </div>
      </div>
      <template v-if="search || !shared.selectedCard">
        <div
          class="suggestion"
          v-for="card in filteredCards"
          :key="card.name"
          @click="selectCard(card)"
        >
          {{ card.name }}
        </div>
      </template>

      <AgendaCard v-else :card="shared.selectedCard" />
    </div>
    <div class="col">
      <template v-if="shared.step === 0">
        <div class="header mt-0">1. Подсчитайте доступные голоса:</div>

        <div class="vote-counters">
          <PlayerVoteCounter
            v-for="(player, index) in shared.players"
            :key="player.name"
            :playerName="player.name"
            :value="player.votes"
            @input="set(`players/${index}/votes`, $event)"
          />
        </div>
        <hr />

        <div class="header">
          2. Начиная с <b>председателя</b>, разыгрывайте способности с условием
          "Когда раскрывается карта политики:"<br />
          <b>(Вето, Убийство представителя и т.п.)</b>
        </div>
      </template>
      <template v-if="shared.step === 1">
        <div class="vote-counters compact">
          <PlayerVoteCounter
            :readonly="true"
            v-for="player in shared.players"
            :key="player.name"
            :playerName="player.name"
            :value="player.votes"
          />
        </div>

        <hr />

        <div class="header">
          3. Начиная с <b>председателя</b>, разыгрывайте способности с условием
          "После того, как раскрыта карта политики:"<br />
          <b>(Поправки, способность Некровируса)</b>:
        </div>

        <!-- rider table - player > outcome, bonus -->
        <table>
          <thead>
            <tr>
              <th>Игрок</th>
              <th>Предсказанный результат, бонус</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rider, index) in shared.riders" :key="index">
              <td>
                <select
                  :value="rider.name"
                  @change="set(`riders/${index}/name`, $event.target.value)"
                >
                  <option selected value=""></option>
                  <option v-for="name in playerNames" :key="name" :value="name">
                    {{ name }}
                  </option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  :value="rider.value"
                  @input="set(`riders/${index}/value`, $event.target.value)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-if="shared.step === 2">
        <div class="vote-counters compact">
          <PlayerVoteCounter
            :readonly="true"
            v-for="player in shared.players"
            :key="player.name"
            :playerName="player.name"
            :value="player.votes"
          />
        </div>

        <hr />

        <template v-if="validRiders.length > 0">
          <div v-for="(rider, index) in validRiders" :key="index">
            {{ rider.name }} - {{ rider.value }}
          </div>

          <hr />
        </template>

        <div class="header">
          4. Начиная с <b>игрока после председателя</b>, голосуйте:
        </div>
        <!-- vote table - outcome > #votes -->
        <table>
          <thead>
            <tr>
              <th>Результат</th>
              <th>Голоса</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(outcome, index) in shared.outcomes" :key="index">
              <td>
                <input
                  type="text"
                  :value="outcome.name"
                  @input="set(`outcomes/${index}/name`, $event.target.value)"
                />
              </td>
              <td>{{ outcome.votes }}</td>
              <td class="vote-buttons">
                <button
                  @click="updateVotes(index, -5)"
                  :disabled="outcome.votes === 0"
                >
                  -5
                </button>
                <button
                  @click="updateVotes(index, -1)"
                  :disabled="outcome.votes === 0"
                >
                  -1
                </button>
                <button @click="updateVotes(index, 1)">+1</button>
                <button @click="updateVotes(index, 5)">+5</button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <button
        class="step-button"
        :disabled="shared.step === 0"
        @click="set('step', shared.step - 1)"
      >
        Назад
      </button>
      <button
        class="step-button"
        :disabled="shared.step === 2"
        @click="set('step', shared.step + 1)"
      >
        Далее
      </button>
      <button class="step-button" v-if="shared.step === 2" @click="cleanup">
        Очистить и вернуться
      </button>
    </div>
  </div>
</template>

<script>
import AgendaCard from "./components/AgendaCard.vue";
import PlayerVoteCounter from "./components/PlayerVoteCounter.vue";
import cards from "@/assets/agendaCards";
import Fuse from "fuse.js";
import db from "./firebase";
import { onValue, set, ref } from "firebase/database";

const fuse = new Fuse(cards, {
  keys: ["name"],
});

export default {
  name: "App",
  components: {
    AgendaCard,
    PlayerVoteCounter,
  },
  data() {
    return {
      cards,
      shared: {
        selectedCard: null,
        players: [],
        step: 0,
        riders: [],
      },
      search: "",
    };
  },
  created() {
    onValue(ref(db, "sharedState"), (snapshot) => {
      this.shared = snapshot.val();
      console.log(this.shared.players[0]);
    });
  },
  computed: {
    filteredCards() {
      if (!this.search) {
        return cards;
      }

      return fuse.search(this.search).map(({ item }) => item);
    },
    playerNames() {
      return this.shared.players.map((p) => p.name);
    },
    validRiders() {
      return this.shared.riders.filter((rider) => {
        return rider.name.length > 0 || rider.value.length > 0;
      });
    },
  },
  methods: {
    selectCard(card) {
      this.set("selectedCard", card);
      this.search = "";
    },
    set(path, value) {
      set(ref(db, `sharedState/${path}`), value);
    },
    updateVotes(index, amount) {
      const newAmount = Math.max(this.shared.outcomes[index].votes + amount, 0);
      this.set(`outcomes/${index}/votes`, newAmount);
    },
    cleanup() {
      if (window.confirm("Очистить поправки и результаты?")) {
        for (var i = 0; i < 5; i++) {
          this.set(`riders/${i}/name`, "");
          this.set(`riders/${i}/value`, "");
          this.set(`outcomes/${i}/name`, "");
          this.set(`outcomes/${i}/votes`, 0);
        }
        this.set("step", 0);
      }
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html {
  font-size: 24px;
  background-color: #fafafa;
}

input,
select {
  background-color: #fafafa;
}

body {
  margin: 0;
  word-break: break-word;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.col {
  padding: 0.75rem;
  border: 1px solid #dadada;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  overflow-y: auto;

  &:first-child {
    flex-grow: 0.6;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  button {
    font-size: 1rem;
    margin-right: 0.5rem;
    white-space: nowrap;
  }

  div {
    flex-grow: 1;
    flex-shrink: 1;
  }

  input {
    font-size: 1rem;
    width: 100%;
  }
}

.suggestion {
  border: 1px solid #dadada;
}

.header {
  margin: 0.5rem 0;
  font-size: 1.25rem;
}

.vote-counters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 400px));
  justify-content: center;
  background-color: #ededed;
  grid-gap: 0.25rem 1rem;
  padding: 0.25rem 0;

  &.compact {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

table {
  margin: 0 auto 0.5rem;
  border: 1px solid #dadada;
  border-collapse: collapse;
  font-size: 1rem;

  th,
  td {
    border: 1px solid #dadada;
    padding: 0.1rem 0.4rem;
  }

  input,
  select {
    font-size: 1rem;
    width: 100%;
    border-color: transparent;
  }

  .vote-buttons {
    white-space: nowrap;
    > * {
      margin-right: 0.25rem;
    }
  }
}

.step-button {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.mt-0 {
  margin-top: 0;
}
</style>
