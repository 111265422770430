<template>
  <div>
    <p class="card-name">{{ card.name }}</p>
    <p class="card-type">
      {{ card.type === "Law" ? "Закон" : "Распоряжение" }}
    </p>
    <p
      :class="`card-text ${paragraph.classList}`"
      v-for="(paragraph, index) in card.paragraphs"
      :key="index"
    >
      {{ paragraph.text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AgendaCard",
  props: ["card"],
};
</script>

<style scoped lang="scss">
p {
  margin: 0.5em 0;
}

.card-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-type {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-text {
  text-align: left;
  font-size: 1rem;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.yes {
  background-color: #9cd09c;
}

.no {
  background-color: #d2b0b0;
}
</style>
