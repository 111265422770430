function p(text) {
  return { text };
}

function bold(text) {
  return { classList: "bold center", text };
}

function center(text) {
  return { classList: "center", text };
}

function yes(text) {
  return { classList: "yes", text };
}

function no(text) {
  return { classList: "no", text };
}

export const unused = [
  {
    name: "Корона Танлоса",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      p(
        "Во время каждого раунда боя владелец этой карты может перебросить любое количество кубиков; он должен уничтожить каждый отряд, который не нанёс попаданий после переброса кубиков."
      ),
    ],
  },
];

let cards = [
  {
    name: "Пророчество икстов",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      p(
        'Этот игрок добавляет +1 к результатам боевых проверок своих истребителей. Когда владелец этой карты использует способность "ПРОИЗВОДСТВО", он сбрасывает эту карты, если не производит при этом хотя бы 2 истребителя.'
      ),
    ],
  },
  {
    name: "Секретные архивы",
    type: "Directive",
    paragraphs: [
      bold("Выберите игрока"),
      p("Выбранный игрок берет 1 карту секретной цели."),
    ],
  },
  {
    name: "Новая конституция",
    type: "Directive",
    paragraphs: [
      center(
        "Если на момент раскрытия этой карты политики в игре нет действующих законов, сбросьте её и раскройте новую карту с верха колоды политики."
      ),
      yes(
        "За: сбросьте карты всех действующих законов. В начале следующей фазы стратегии каждый игрок переворачивает все карты планет в своей родной системе."
      ),
      no("Против: ничего не происходит"),
    ],
  },
  {
    name: "Анти-технологическая революция",
    type: "Law",
    paragraphs: [
      yes(
        "За: после того, как игрок исследовал технологию, он должен уничтожить 1 свой корабль (кроме истребителей)."
      ),
      no(
        "Против: в начале следующей фазы стратегии каждый игрок переворачивает 1 карту планеты за каждую технологию, которой владеет."
      ),
    ],
  },
  {
    name: "Ростки империи",
    type: "Directive",
    paragraphs: [
      yes(
        "За: игроки, у которых больше всего победных очков, получают 1 победное очко."
      ),
      no(
        "Против: игроки, у которых меньше всего победных очков, получают 1 победное очко."
      ),
    ],
  },
  {
    name: "Программа поощрений",
    type: "Directive",
    paragraphs: [
      yes(
        "За: возьмите и раскройте 1 карту общей цели этапа 1. Выложите её рядом с картами общих целей."
      ),
      no(
        "Против: возьмите и раскройте 1 карту общей цели этапа 2. Выложите её рядом с картами общих целей."
      ),
    ],
  },
  {
    name: "Корона Эмфидии",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center(
        "Выбранный игрок получает эту карту и 1 победное очко. Другой игрок получает эту карту и 1 победное очко после того, как получил контроль над планетой в родной системе обладателя этой карты."
      ),
      center("Затем предыдущий владелец этой карты теряет 1 победное очко."),
    ],
  },
  {
    name: "Ограничение полномочий",
    type: "Law",
    paragraphs: [
      yes("За: у каждого игрока на руке может быть не более 3 карт действий."),
      no("Против: каждый игрок сбрасывает 1 случайную карту действия с руки."),
    ],
  },
  {
    name: "Раскрытие военных чертежей",
    type: "Law",
    paragraphs: [
      yes(
        'За: если у любого игрока есть технология солнца войны, все игроки могут игнорировать требования технологий солнца войны. Все солнца войны теряют способность "Поглощение урона".'
      ),
      no(
        "Против: каждый игрок, у которого есть технология солнца войны, сбрасывает все свои карты действий."
      ),
    ],
  },
  {
    name: "Министр торговли",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        "После того, как владелец этой карты пополнил продукцию, он получает 1 товар за каждого соседнего игрока."
      ),
    ],
  },
  {
    name: "Утечка секретной информации",
    type: "Law",
    paragraphs: [
      center(
        "Если на момент раскрытия этой карты политики в игре нет выполненных секретных целей, сбросьте её и раскройте новую карту с верха колоды политики."
      ),
      bold("Выберите выполненную секретную цель"),
      center(
        "Выбранная секретная цель становится общей целью; выложите её карту рядом с остальными общими целями в общей игровой зоне."
      ),
    ],
  },
  {
    name: "Договор о разоружении",
    type: "Directive",
    paragraphs: [
      yes(
        "За: каждый игрок оставляет себе 2 дредноута и 4 крейсера и уничтожает все остальные свои отряды этих типов."
      ),
      no(
        "Против: в начале следующей фазы стратегии игроки переворачивают все карты своих планет с технологической специализацией."
      ),
    ],
  },
  {
    name: "Экономическое равенство",
    type: "Directive",
    paragraphs: [
      yes(
        "За: каждый игрок возвращает все свои товары в общий запас. Затем каждый игрок получает 5 товаров."
      ),
      no("Против: каждый игрок возвращает все свои товары в общий запас."),
    ],
  },
  {
    name: "Ограничение на передвижение",
    type: "Law",
    paragraphs: [
      yes(
        "За: при передвижении нельзя использовать альфа- и бета-червоточины."
      ),
      no(
        "Против: уничтожьте все ПСО в системах с червоточинами и в смежных системах."
      ),
    ],
  },
  {
    name: "Нетрадиционные меры",
    type: "Directive",
    paragraphs: [
      yes('За: каждый игрок, проголосовавший "За", берет по 2 карты действий.'),
      no(
        'Против:  каждый игрок, проголосовавший "За", сбрасывает все свои карты действий.'
      ),
    ],
  },
  {
    name: "Министр науки",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        'Когда владелец этой карты применяет основную или второстепенную способность карты стратегии "Исследование", ему не нужно тратить очки ресурсов, чтобы исследовать технологию.'
      ),
    ],
  },
  {
    name: "Имперский арбитр",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        "В конце фазы стратегии владелец этой карты может сбросить ее, чтобы обменять 1 из своих карт стратегии на 1 карту стратегии другого игрока."
      ),
    ],
  },
  {
    name: "Министр промышленности",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        'Когда владелец этой карты размещает базу в системе, его отряды в этой системе могут использовать свою способность "ПРОИЗВОДСТВО".'
      ),
    ],
  },
  {
    name: "Министр войны",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        "Совершив действие, владелец этой карты может сбросить ее, чтобы вернуть 1 свой жетон приказа с поля в снабжение, затем он может совершить 1 дополнительное действие."
      ),
    ],
  },
  {
    name: "Министр мира",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        "После того, как игрок активировал систему, в которой есть хотя бы 1 отряд другого игрока, владелец этой карты может сбросить ее; ход активного игрока немедленно завершается."
      ),
    ],
  },
  {
    name: "Регулирование флота",
    type: "Law",
    paragraphs: [
      yes(
        "За: у каждого игрока не может быть больше 4 жетонов в резерве флота."
      ),
      no(
        "Против: каждый игрок кладёт 1 жетон приказа из снабжение в свой резерв флота."
      ),
    ],
  },
  {
    name: "Осколок власти",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center(
        "Выбранный игрок получает эту карту и 1 победное очко. Другой игрок получает эту карту и 1 победное очко, когда выигрывает бой против обладателя этой карты."
      ),
      center("Затем предыдущий владелец этой карты теряет 1 победное очко."),
    ],
  },
  {
    name: "Оплачиваемое разоружение",
    type: "Directive",
    paragraphs: [
      bold("Выберите планету"),
      center(
        "Уничтожьте все наземные войска на выбранной планете. Затем игрок, контролирующий эту планету, получает 1 товар за каждый уничтоженный отряд."
      ),
    ],
  },
  {
    name: "Бунт",
    type: "Directive",
    paragraphs: [
      yes('За: каждый игрок, проголосовавший "За", получает 1 победное очко.'),
      no('Против: каждый игрок, проголосовавший "За", теряет 1 победное очко.'),
    ],
  },
  {
    name: "Плановый призыв",
    type: "Law",
    paragraphs: [
      yes(
        "За: когда игрок производит отряды, он производит только 1 истребитель или отряд пехоты за обычную цену, а не 2."
      ),
      no("Против: ничего не происходит."),
    ],
  },
  {
    name: "Исследование червоточин",
    type: "Directive",
    paragraphs: [
      yes(
        "За: каждый игрок, у которого есть хотя бы 1 корабль в системе с червоточиной, может исследовать 1 технологию. Затем уничтожьте все корабли в системах с альфа- или бета-червоточинами."
      ),
      no(
        'Против: каждый игрок, проголосовавший "Против", возвращает 1 жетон приказа со своего планшета приказов в снабжение.'
      ),
    ],
  },
  {
    name: "Артефакт икстов",
    type: "Directive",
    paragraphs: [
      yes(
        "За: председатель бросает 1 кубик. Если результат 6-10: каждый игрок может исследовать 2 технологии. Если результат 1-5: уничтожьте все отряды в системе Мекатол-Рекса; каждый игрок, у которого есть отряды в системах, смежных с ней, уничтожает 3 своих отряда в каждой такой системе."
      ),
      no("Против: ничего не происходит."),
    ],
  },
  {
    name: "Мечи на орала",
    type: "Directive",
    paragraphs: [
      yes(
        "За: каждый игрок уничтожает половину своих отрядов пехоты (с округлением вверх) на каждой подконтрольной ему планете. Затем каждый игрок получает столько товаров, сколько он уничтожил отрядов пехоты."
      ),
      no(
        "Против: каждый игрок размещает 1 отряд пехоты из снабжения на каждой подконтрольной ему планете."
      ),
    ],
  },
  {
    name: "Публичная казнь",
    type: "Directive",
    paragraphs: [
      bold("Выберите игрока"),
      center(
        "Выбранный игрок сбрасывает все свои карты действий. Если у него есть жетон председателя, он отдает его игроку слева. Выбранный игрок не может голосовать по любым картам во время этой фазы политики."
      ),
    ],
  },
  {
    name: "Передел колоний",
    type: "Directive",
    paragraphs: [
      bold("Выберите планету (за исключением родных планет и Мекатол-Рекса):"),
      center(
        "Уничтожьте все отряды на выбранной планете. Затем игрок, контролирующий эту планету, выбирает игрока с наименьшим количеством победных очков; этот игрок может разместить 1 отряд пехоты из своего снабжения на выбранной планете."
      ),
    ],
  },
  {
    name: "Священная планета икстов",
    type: "Law",
    paragraphs: [
      bold("Выберите культурную планету"),
      center(
        'Прикрепите эту карту к карте выбранной планеты. Владелец планеты получает 1 победное очко. Отряды на этой планете не могут использовать способность "ПРОИЗВОДСТВО". Когда игрок получает контроль над этой планетой, он получает 1 победное очко. Когда игрок теряет контроль над этой планетой, он теряет 1 победное очко.'
      ),
    ],
  },
  {
    name: "Закон об обороне",
    type: "Law",
    paragraphs: [
      yes(
        "За: каждый игрок может размещать любое количество ПСО на подконтрольных ему планетах."
      ),
      no("Против: каждый игрок уничтожает 1 из своих ПСО."),
    ],
  },
  {
    name: "Перестройка червоточин",
    type: "Law",
    paragraphs: [
      yes(
        "За: все системы, в которых есть альфа- или бета-червоточины, считаются смежными."
      ),
      no(
        "Против: каждый игрок кладёт 1 жетон приказа из своего снабжения в каждую систему, где есть червоточина и хотя бы 1 его корабль."
      ),
    ],
  },
  {
    name: "Демилитаризированная зона",
    type: "Law",
    paragraphs: [
      bold("Выберите культурную планету"),
      center(
        "Прикрепите эту карту к карте выбранной планеты. Затем уничтожьте все отряды на этой планете."
      ),
      center(
        "Игроки не могут высаживать, производить или размещать отряды на этой планете."
      ),
    ],
  },
  {
    name: "Разработки ядра",
    type: "Law",
    paragraphs: [
      bold("Выберите опасную планету"),
      center(
        "Прикрепите эту карту к карте выбранной планеты. Затем уничтожьте 1 отряд пехоты на этой планете."
      ),
      center("Значение ресурсов этой планеты увеличивается на 2."),
    ],
  },
  {
    name: "Министр открытий",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту."),
      center(
        "Когда владелец этой карты получает контроль над планетой, он получает 1 товар."
      ),
    ],
  },
];

// in expansion, remove the following:
// * = card already not on list
/*
✦ Core Mining*
✦ Demilitarized Zone
✦ Holy Planet of Ixth
✦ Representative Government*
✦ Research Team: Biotic*
✦ Research Team: Cybernetic*
✦ Research Team: Propulsion*
✦ Research Team: Warfare*
✦ Senate Sanctuary*
✦ Shard of the Throne
✦ Terraforming Initiative*
✦ The Crown of Emphidia
✦ The Crown of Thalnos*
*/

const expansionCards = [
  {
    name: "Перераспределение гранта",
    type: "Directive",
    paragraphs: [
      bold("Выберите игрока"),
      center(
        "Выбранный игрок получает 1 любую технологию на свой выбор. Затем за каждое требование на этой карте технологии он убирает в снабжение 1 жетон из своего резерва флота."
      ),
    ],
  },
  {
    name: "Тайные операции",
    type: "Directive",
    paragraphs: [
      yes(
        "За: Каждый игрок убирает 2 жетона приказов со своего планшета приказов и возвращает их в снабжение."
      ),
      no(
        "Против: Каждый игрок убирает 1 жетона приказа из своего резерва флота и возвращает его в снабжение."
      ),
    ],
  },
  {
    name: "Соглашение о перевооружении",
    type: "Directive",
    paragraphs: [
      yes(
        "За: Каждый игрок размещает 1 меха из своего снабжения на подконтрольной ему планете в его родной системе."
      ),
      no(
        "Против: Каждый игрок заменяет каждого своего меха на отряд пехоты из своего снабжения."
      ),
    ],
  },
  {
    name: "Галактический сенат",
    type: "Directive",
    paragraphs: [
      yes(
        "За: Игроки не могут переворачивать карты планет, чтобы голосовать во время фазы политики, теперь каждый игрок может отдать только 1 голос по каждой карте политики. Игроки не могут добавлять дополнительные голоса."
      ),
      no(
        'Против: В начале следующей фазы стратегии каждый игрок, проголосовавший "против", переворачивает все карты своих культурных планет.'
      ),
    ],
  },
  {
    name: "Пакт о галактическом кризисе",
    type: "Directive",
    paragraphs: [
      bold("Выберите карту стратегии"),
      center(
        "Каждый игрок может применить второстепенную способность выбранной карты стратегии, не тратя жетон приказа; если эта способность требует выложить куда-либо жетоны приказов, они выкладываются из снабжения игроков."
      ),
    ],
  },
  {
    name: "Политический ценз",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту и 1 победное очко."),
      center("Выбранный игрок не может играть карты действий."),
      center("Если владелец этой карты теряет её, он теряет 1 победное очко."),
    ],
  },

  {
    name: "Министр древностей",
    type: "Directive",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает 1 реликвию."),
    ],
  },

  {
    name: "Сдержки и противовесы",
    type: "Law",
    paragraphs: [
      yes(
        "За: Когда любой игрок выбирает карту стратегии в фазе стратегии, он при возможности отдаёт её другому игроку, у которого ещё нет карт стратегии (или нет двух карт при игре втроём или вчетвером)."
      ),
      no(
        "Против: В конце этой фазы политики каждый игрок подготавливает только 3 свои карты планет."
      ),
    ],
  },

  {
    name: "Власть над ядром",
    type: "Law",
    paragraphs: [
      yes(
        "За: альфа- и бета-червоточины в ядре червоточин не имеют эффекта во время передвижения."
      ),
      no("Против: положите жетон гамма-червоточины в систему Мекатол-Рекс."),
    ],
  },

  {
    name: "Тайный законопроект",
    type: "Directive",
    paragraphs: [
      center(
        "Когда раскрыта эта карта политики, председатель берёт следующую карту из колоды политики, но не раскрывает её остальным игрокам. Вместо этого председатель зачитывает вслух только доступные варианты решений (за, против, выберите игрока и т.д.), остальные игроки голосуют, не зная содержания этих решений."
      ),
    ],
  },

  {
    name: "Военный устав",
    type: "Law",
    paragraphs: [
      yes(
        'За: все мехи теряют все указанные на их картах способности, кроме "Поглощения урона".'
      ),
      no("Против: Каждый игрок, проголосовавший 'За', получает 3 товара."),
    ],
  },

  {
    name: "Стандартизация вооружённых сил",
    type: "Directive",
    paragraphs: [
      bold("Выберите игрока"),
      center(
        "Выбранный игрок перераспределяет жетоны на своём планшете приказов, так чтобы в его тактическом резерве было 3 жетона, в резерве флота - 3 жетона, а в стратегическом резерве - 2 жетона. Недостающие жетоны берутся из снабжения, лишние - возвращаются в снабжение."
      ),
    ],
  },

  {
    name: "Ордер на обыск",
    type: "Law",
    paragraphs: [
      bold("Выберите игрока"),
      center("Выбранный игрок получает эту карту и берёт 2 секретные цели."),
      center(
        "Владелец этой каты держит все свои карты секретных целей в открытую."
      ),
    ],
  },
];

cards = cards.concat(expansionCards);

cards.sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  } else {
    return 0;
  }
});

export default cards;
